<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Agregar Clinica </ion-title>
    <ion-form>
      <ion-input type="text" id="nombre"  v-model="nombre"  placeholder="Ingrese el nombre" />
      <ion-input type="text" id="contacto"  v-model="contacto"  placeholder="Ingrese el contacto" />
      <ion-input type="text" id="telefono"  v-model="telefono"  placeholder="Ingrese el teléfono" />
      
      <ion-select class="input-text"  placeholder="Ingrese la provincia"  interface="action-sheet" v-model="provincia" > 
        <ion-select-option v-for="provincia in provincias" :value="provincia.name" :key="provincia" >{{provincia.name}}</ion-select-option>
      </ion-select>
      <ion-input type="text" id="poblacion" v-model="poblacion"   placeholder="Ingrese la población" />
      <ion-textarea type="text" id="observacion" v-model="observacion"   placeholder="Ingrese la observación" />
      <ion-button color="dark" @click="postClinica">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import provincias from './provincia_spain.json';
import { modalController,IonInput ,IonTextarea,IonSelect,IonSelectOption} from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  components : { IonInput ,IonTextarea,IonSelect,IonSelectOption},
  setup() {
    return { close }
  },
  data(){
    return{
      contacto: null,
      nombre: null,
      telefono: null,
      poblacion:null,
      provincia : null,
      observacion : null,
      provincias : provincias
    }
  },
  mounted(){
    console.log(provincias)
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    async postClinica(){

      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/clinicas',{
          nombre : this.nombre,
          contacto : this.contacto,
          telefono : this.telefono,
          poblacion : this.poblacion,
          provincia : this.provincia,
          observacion : this.observacion
      })
      .then(data =>{
        loading.dismiss()
        this.closeModal()
        this.$toast.openToast("Registro de clinica exitoso","success")
        console.log(data)
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}
ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

.ion-sel-3-lbl{
  color: blue;
}
</style>
